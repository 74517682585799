import React, { useState, useEffect, useRef } from 'react';
import { celebrityList } from '../data.json';

import * as faceapi from 'face-api.js';
const {
  detectSingleFace,
  loadFaceRecognitionModel,
  loadSsdMobilenetv1Model,
  LabeledFaceDescriptors,
  fetchImage,
  fetchJson,
  FaceMatcher,
  loadFaceLandmarkModel,
  FaceMatch,
} = faceapi;

const Home = () => {
  const [userPicture, setUserPicture] = useState();
  const [bestMatches, setBestMatches] = useState();
  const [analysing, setAnalysing] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [modelLoaded, setModelLoaded] = useState(false);
  const imagesModelFormated = useRef([]);
  const faceMatcher = useRef();
  const singleResult = useRef();
  const hiddenFileInput = useRef();

  const init = async () => {
    try {
      await loadFaceRecognitionModel('/models');
      await loadFaceLandmarkModel('/models');
      await loadSsdMobilenetv1Model('/models');

      //   await faceapi.nets.faceRecognitionNet.loadFromUri('/models');
      //   await faceapi.nets.faceLandmark68Net.loadFromUri('/models');
      //   await faceapi.nets.ssdMobilenetv1.loadFromUri('/models');
      const imagesModel = await fetchJson('/imagesModel.json');
      setModelLoaded(true);

      imagesModelFormated.current = imagesModel.reduce((acc, el) => {
        if (el) {
          return [
            ...acc,
            new LabeledFaceDescriptors(
              el.label,
              el.descriptors.map((desc) => new Float32Array(desc)),
            ),
          ];
        }
        return acc;
      }, []);

      faceMatcher.current = new FaceMatcher(imagesModelFormated.current, 1);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const unhandledrejection = (event) => {
      // the event object has two special properties:
      setErrorMessage('Problème avec la photo');
      setAnalysing(false);
    };

    window.addEventListener('unhandledrejection', unhandledrejection);

    init();

    return () => {
      window.removeEventListener('unhandledrejection', unhandledrejection);
    };
  }, []);

  const handleInputChange = async (event) => {
    if (event.target.files && event.target.files[0]) {
      setUserPicture(URL.createObjectURL(event.target.files[0]));
    }
  };

  const handleInputClick = (event) => {
    setBestMatches(null);
    setErrorMessage(null);
    hiddenFileInput.current.click();
  };

  const handleFindYourDoppelganger = async () => {
    setAnalysing(true);
    const userImg = await fetchImage(userPicture);
    try {
      singleResult.current = await detectSingleFace(userImg)
        .withFaceLandmarks()
        .withFaceDescriptor();
    } catch (err) {
      console.log(err);
    }

    if (singleResult.current) {
      setBestMatches(
        imagesModelFormated.current
          .map(({ descriptors, label }) => {
            const descriptor = faceMatcher.current.computeMeanDistance(
              singleResult.current.descriptor,
              descriptors,
            );
            const facematch = new FaceMatch(label, descriptor);
            return facematch;
          })
          .sort((a, b) => {
            return a.distance - b.distance;
          })
          .slice(0, 20),
      );
    } else {
      setErrorMessage('Aucun visage detecté sur la photo.');
    }

    setAnalysing(false);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ padding: 20 }}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <h1
            style={{
              display: 'inline-block',
              position: 'relative',
            }}
          >
            Trouve ton sosie célèbre{' '}
            <span
              style={{
                fontSize: 12,
                color: 'red',
              }}
            >
              Beta
            </span>
          </h1>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginBottom: 20,
          }}
        >
          <div
            style={{ display: 'flex', justifyContent: 'center', padding: 20 }}
          >
            <div
              style={{
                background: modelLoaded ? 'blue' : 'grey',
                color: '#fff',
                padding: 20,
                borderRadius: 15,
                cursor: 'pointer',
                width: 'auto',
              }}
              onClick={!analysing && modelLoaded ? handleInputClick : () => {}}
            >
              {modelLoaded ? 'Selectionne ta photo' : 'Chargement en cours'}
            </div>
            <input
              type="file"
              onChange={handleInputChange}
              accept=".jpg, .jpeg, .png"
              style={{ display: 'none' }}
              ref={hiddenFileInput}
            />
          </div>
          <span style={{ fontSize: 12, maxWidth: 400, width: '100%' }}>
            Aucune photo n'est enregistrée. On analyse l'image dans le
            navigateur grâce à l'intelligence artificiel et on la compare a une
            base de données de {celebrityList.length} célébrités.
          </span>
        </div>

        {userPicture && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: 20,
            }}
          >
            <img style={{ maxHeight: 200, width: 'auto' }} src={userPicture} />
          </div>
        )}
        {!bestMatches && userPicture && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                background: !analysing ? 'green' : 'grey',
                color: '#fff',
                padding: 20,
                borderRadius: 15,
                cursor: 'pointer',
                width: 'auto',
              }}
              onClick={!analysing ? handleFindYourDoppelganger : () => {}}
            >
              {!analysing ? 'Trouve ton sosie' : 'Analyse en cours'}
            </div>
            {errorMessage && (
              <div style={{ marginTop: 10 }}>
                Aucun visage détecté sur la photo.
              </div>
            )}
          </div>
        )}

        {bestMatches && (
          <div style={{ marginBottom: 50 }}>
            <span>Tes sosies sont : </span>
            <div style={{ display: 'flex', overflow: 'auto' }}>
              {bestMatches.map((el) => (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: '0 0 auto',
                    padding: 10,
                    alignItems: 'center',
                  }}
                  key={el.distance}
                >
                  <div
                    style={{
                      width: 120,
                      height: 160,
                      backgroundImage: `url("/celebrityList/${
                        JSON.parse(el.label).picture
                      }")`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      marginBottom: 10,
                    }}
                  ></div>

                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div>{JSON.parse(el.label).name} </div>
                    <div style={{ fontWeight: 700 }}>
                      Sosie {Math.round((1 - el.distance) * 100)}%
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <div style={{ position: 'fixed', bottom: 10, right: 10 }}>
        <a style={{ color: '#000' }} href="http://www.jeremydelacasa.com">
          jeremydelacasa
        </a>
      </div>
    </div>
  );
};
export default Home;
